import React from "react"
import Layout from "@components/kaigai-fx/layout"
import FormsParts from "@components/kaigai-fx/forms-contactform7-fx";

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/cat_icon01.svg';
import icon_post_SVG3 from '@images/kaigai-fx/post_icon3.svg';

// WP CSS
import "@css/@wordpress/block-library/build-style/style.css"
import "@css/@wordpress/block-library/build-style/theme.css"

// コンポーネント呼び出し
import { GraphqlArray } from "@components/kaigai-fx/graphql-data-post.js"; // 記事情報取得・形成
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成
import { FixedFoot } from "@components/kaigai-fx/footer";
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定

const FxContact = () => {
  const indexUrl = '/kaigai-fx/';
  const pageTitle = 'お問い合わせ'
  const name = '業者情報' // 関連取得の為ダミータグ設定  
  const searchList = SearchCategList().Main; // カテゴリ検索リスト
  const wordSearchContentPC = WordSearch().PC; // ワード検索PC
  const wordSearchContentMB = WordSearch().MB; // ワード検索MB
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  // graphql-data-post.jsから記事データ呼び出し
  var checkName = name ;
  const allPostData = GraphqlArray(checkName, 'allmix');
  var setArray;
  setArray = allPostData.checkTag; // 該当タグ配列情報

  // Seo情報設定
  const pathName = 'contact';
  const seoTag = SeoContents({ Path:pathName });

  // 関連記事取得(components/related-article.js)
  let relatedTitle, relatedList;
  if ( setArray[0] !== undefined ) { // 記事のないカテゴリ生成時のエラー回避
    relatedTitle = setArray[0].Title; // 仮で一番目の記事の関連を取得
    relatedList = RelatedContents( allPostData, relatedTitle );
  } 

  // パンくず設定
  var topicTagPath =  <>
                        <a href={indexUrl} className="px-2 hover:text-gray-400">ホーム</a>
                          <span className="pr-2 mx-0 my-auto inset-y-0 md:m-0">＞</span>
                        <span className="pr-2">{pageTitle}</span>
                      </>;

  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB                    
      
  return (
    <> 
      <Layout>
        {seoTag}
        <div className="md:max-w-6xl md:flex justify-between mx-auto md:pt-10">
          <div className="contact_cont w-full max-w-4xl md:pr-4 px-0">
          <div>
            <div className="blog_h1_bg">
            <h1 className="ml-5 font-semibold tracking-wide text-[30px] px-4 md:text-[30px] text-white table lg:min-w-[835px]" itemProp="headline">{pageTitle}</h1>
            </div>
         </div>

            {/* パンくず */}
            <p className="text-[12px] mb-[20px] md:mb-[30px]">
              <div className="flex mb-2 md:mb-3 md:px-0 px-4">
                <img className="pr2" alt="" src={icon_post_SVG3}/>
                {topicTagPath}
              </div>
            </p>

            {/* １カラムコンテンツ */}
            {/* divだとbuild時置き換えが起こるのでpタグに */}
            <p className="md:px-0 px-4"> 

              <FormsParts/>
            </p>

          </div>

          <div className="md:max-w-xs pl-4 pr-4 md:pl-3 md:pr-4 lg:pr-0">
              
            <div>

              {/*関連記事 */}
              <div className="md:max-w-[320px] max-w-[380px] my-0 mx-auto">
                <h3 className="font-semibold tracking-wide text-lg md:text-xl first-letter:text-[#0028C9] text-[#424242]">
                  関連記事
                  <span><img className="mb-5 mx-auto h-auto inline  pl-2 box-border" alt="" width="36" height="36" src={icon_t_SVG1}/></span>
                </h3>
                <div className="flex-wrap justify-between mb-4 ">
                  {relatedList}
                </div>
              </div>

            </div>
            

            {/*カテゴリ検索 */}
            <div>
              {searchList}
            </div>

            {/*ワード検索 */}
            {wordSearchContentPC}

                        {/* PCプロフィール */}
                        {profilePC}

          </div>
        </div>
        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}

          {/* MBプロフィール */}
          {profileMB}
          
        </div>
        {/* 固定フッター */}
        {fixedItem}   
      </Layout> 
    </>
  )
}
export default FxContact
